import { i18n } from "@/plugins/i18n";
import { TableFieldType, TableHeader } from "helix-vue-components";

export const BatchLevelTableMetadata: TableHeader[] = [
  {
    value: "name",
    label: i18n.t("batch_level_pricing.name").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    value: "rule_type",
    label: i18n.t("batch_level_pricing.type").toString(),
    fieldComponent: TableFieldType.enumText,
    enum: {
      PRICE_BREAK_RULE: i18n.t("price_point").toString(),
      MARKUP_RULE: i18n.t("percentage").toString()
    },
    class: "tdt__headers__fieldLong",
    sortable: true
  }
];
