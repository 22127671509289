import { ResetService } from "@/decorators/resetService.decorator";
import { policyList } from "@/enums/permissions";
import { HttpQuery } from "@/interfaces/httpQuery";
import { PriceGroup } from "@/interfaces/product";
import { pusherEvents } from "@/internal";
import { BatchLevelTableMetadata } from "@/metadata/batchLevel";
import { batchLevelActionService } from "@/services/batchLevel.action.service";
import { batchLevelService } from "@/services/batchLevel.service";
import { Callback, PageNavAction } from "@/types/types";
import {
  ActionsSubheaderComponent,
  ActionSubheader,
  BooleanCheck,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./BatchLevelPricingList.template.vue";
const namespace: string = "BatchLevelModule";

@Component({
  mixins: [Template],
  components: {
    ActionsSubheaderComponent,
    TableComponent
  },
  inject: ["$changes"]
})
@ResetService(batchLevelService)
export default class BatchLevelListComponent extends Vue {
  @Getter("batchLevels", { namespace })
  public batchLevels!: PriceGroup[];
  @Getter("pagination", { namespace })
  public pagination!: TablePagination;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Getter("loading", { namespace })
  public loading!: boolean;
  public showFilters = false;
  public activeFilter = true;
  public enableMultiActions = true;
  public headers: TableHeader[] = BatchLevelTableMetadata;
  public generalActions: ActionSubheader[] = batchLevelActionService.getGeneralActions(
    this.filtersCallback
  );
  public rowActions: TableAction[] = batchLevelActionService.getRowActions();
  public multiActions:
    | TableAction[]
    | null = batchLevelActionService.getMultiActions();

  @Action("loadBatchLevels", { namespace })
  public loadBatchLevelsAction!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  public filter(header: TableHeader) {
    batchLevelService.sortQuery(header);
  }

  public filtersCallback() {
    this.showFilters = !this.showFilters;
    if (!this.showFilters && !this.activeFilter) {
      this.onBatchLevelFilter(!this.showFilters);
    }
  }

  public async triggerFilter(query?: HttpQuery) {
    batchLevelService.setQuery(query);
    this.loadBatchLevelsAction();
  }

  public onBatchLevelFilter(active: boolean) {
    this.activeFilter = active;
    const param = active
      ? { "q[is_active_is_true]": 1 }
      : { "q[is_active_is_false]": 1 };
    this.triggerFilter(param);
  }

  public changePagination(pagination: TablePagination) {
    const paginate = batchLevelService.paginationAction();
    paginate(pagination);
  }

  public back() {
    this.$router.push("/inventory/pricing");
  }

  protected mounted() {
    this.setPageNav({
      title: "batch_level_pricing.title",
      rightActions: {
        generalActions: () => this.generalActions
      },
      leftActions: {
        component: ActionsSubheaderComponent,
        props: {
          generalActions: [
            {
              icon: "fal fa-chevron-left",
              action: this.back
            }
          ]
        }
      }
    });
    this.onBatchLevelFilter(true);
    this.$changes.watch(
      pusherEvents.batchPriceLevelTouched,
      this.loadBatchLevelsAction
    );
    this.enableMultiActions = this.hasPermission(
      policyList.enableDisableBatchLevelPricing
    );
  }
}
