import { policyList } from "@/enums/permissions";
import { BatchType } from "@/interfaces/batchType";
import { store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { ActionSubheader, TableAction } from "helix-vue-components";
import { batchLevelService } from "./batchLevel.service";

class BatchLevelActionService {
  public getGeneralActions(filtersCallback: () => void): ActionSubheader[] {
    return [
      {
        icon: "fal fa-plus",
        action: () => {
          store.dispatch("RouterModule/go", {
            name: "batch-level-add"
          });
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.createBatchLevelPricing
          )
      },
      {
        icon: "fal fa-search",
        otherComponent: {
          name: "TableSearchComponent",
          action: batchLevelService.searchEvent()
        }
      },
      {
        icon: "fal fa-filter",
        action: filtersCallback
      }
    ];
  }

  public getRowActions(): TableAction[] {
    return [
      {
        icon: store.getters["PermissionsModule/hasPermission"](
          policyList.modifyBatchLevelPricing
        )
          ? "fal fa-pen"
          : "fal fa-eye",
        action: batchLevel => {
          store.dispatch("RouterModule/go", {
            name: store.getters["PermissionsModule/hasPermission"](
              policyList.modifyBatchLevelPricing
            )
              ? "batch-level-edit"
              : "batch-level-view",
            params: {
              id: batchLevel.uid,
              currentModel: batchLevel
            }
          });
        }
      },
      {
        icon: "",
        switch: "is_active",
        modalActions: {
          modalNumber: 1,
          modalQuestion: i18n
            .t("batch_level_pricing.disable_confirm")
            .toString(),
          modalSuccessText: "yes",
          modalIdSuccess: "btn_delete_yes",
          modalSuccessAction: ({ item, unselectModal, modelValue }) => {
            batchLevelService
              .toggleBatchType(item, modelValue)
              .then(() => unselectModal())
              .finally(() =>
                store.dispatch("BatchLevelModule/loadBatchLevels")
              );
          },
          modalCancelText: "No"
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableBatchLevelPricing
          )
      }
    ];
  }
  public getMultiActions(): TableAction[] | null {
    return [
      {
        icon: "fal fa-eye",
        action(args: BatchType[]) {
          store.dispatch("BatchLevelModule/enableMultiple", args);
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableBrands
          )
      },
      {
        icon: "fal fa-eye-slash",
        action(args: BatchType[]) {
          store.dispatch("BatchLevelModule/disableMultiple", args);
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableBrands
          )
      }
    ];
  }
}

export const batchLevelActionService = new BatchLevelActionService();
